import React from "react";
import porzio_gst_icon from "../../../public/images/porzio_global_spend_transparency_logo.png";
import MatThemeService from "../../../shared/services/theme/matThemeService";

export default function LogoComponent() {
    const currentTheme = MatThemeService.getTheme();
    const logoBackgroundColor = MatThemeService.isDarkMode() ? "#212121" : "#fff";  // this gray works too: #afbcbc
    let topBarColor = MatThemeService.isDarkMode() ? "#212121" : currentTheme.palette.primary.main;

    return (

        <div style={{ display: "flex" }}>
            <div style={{ width: 50, background: `linear-gradient(to right, ${topBarColor}, ${logoBackgroundColor})` }}>
                &nbsp;
            </div>
            <div style={{ background: `${logoBackgroundColor}` }}>
                <img src={porzio_gst_icon} style={{ width: 200 }} alt="Porzio Global Spend Transparency Logo" />
            </div>
            <div style={{ width: 50, background: `linear-gradient(to right, ${logoBackgroundColor}, ${topBarColor})` }}>
                &nbsp;
            </div>
        </div>

    );
}